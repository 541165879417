/**
 * Extension methods for HTMLElement
 */
declare global {
    export interface HTMLElement {
        getStringAttributeValue(attributeName: string, valueIfNotFound: string | null): string;
        getNumberAttributeValue(attributeName: string, valueIfNotFound: number | null): number;
        getBooleanAttributeValue(attributeName: string, valueIfNotFound: boolean | null): boolean;
        getStringAttributeValueOrNull(attributeName: string): string | null;
        getNumberAttributeValueOrNull(attributeName: string): number | null;
        getBooleanAttributeValueOrNull(attributeName: string): boolean | null;
    }
}

HTMLElement.prototype.getStringAttributeValue
    = function(attributeName: string, valueIfNotFound: string | null | undefined = null): string {
        const result: string | null = this.getStringAttributeValueOrNull(attributeName);
        if (result != null) {
            return result;
        }
        if (valueIfNotFound === null) {
            throw new Error(`Attribute ${attributeName} not found on element ${this.tagName}`);
        }
        return valueIfNotFound;
    };

HTMLElement.prototype.getNumberAttributeValue
    = function(attributeName: string, valueIfNotFound: number | null | undefined = null): number {
        const result: number | null = this.getNumberAttributeValueOrNull(attributeName);
        if (result != null) {
            return result;
        }
        if (valueIfNotFound === null) {
            throw new Error(`Attribute ${attributeName} not found on element ${this.tagName}`);
        }
        return valueIfNotFound;
    };

HTMLElement.prototype.getBooleanAttributeValue
    = function(attributeName: string, valueIfNotFound: boolean | null | undefined = null): boolean {
        const result: boolean | null = this.getBooleanAttributeValueOrNull(attributeName);
        if (result != null) {
            return result;
        }
        if (valueIfNotFound === null) {
            throw new Error(`Attribute ${attributeName} not found on element ${this.tagName}`);
        }
        return valueIfNotFound;
    };

HTMLElement.prototype.getStringAttributeValueOrNull
    = function(attributeName: string): string | null {
        const stringValue: string | null = this.getAttribute(attributeName);
        if (stringValue != null && stringValue != undefined && stringValue.toLowerCase() == "null") {
            return null;
        }
        return stringValue;
    };

HTMLElement.prototype.getNumberAttributeValueOrNull
    = function(attributeName: string): number | null {
        const stringValue: string | null = this.getAttribute(attributeName);
        if (stringValue != null && stringValue != undefined && stringValue.toLowerCase() == "null") {
            return null;
        }
        return stringValue !== null && stringValue !== undefined
            ? Number(stringValue).valueOf()
            : null;
    };

HTMLElement.prototype.getBooleanAttributeValueOrNull
    = function(attributeName: string): boolean | null {
        const stringValue: string | null = this.getAttribute(attributeName);
        if (stringValue == null) {
            return null;
        }
        return stringValue.toLowerCase() == 'true';
    };

export {};
